import axios from "./ECommerce.axio";
import moment from 'moment'
const storeName = "_ :: E-Commerce ::>";
function calcularTotales(cobros) {
    let valores = {
        documentos: [],
        monto: 0,
        interes: 0,
        multa: 0,
        total: 0
    }

    if (cobros) {
        cobros.forEach(recibo => {
            valores.documentos.push(recibo)
            valores.monto += recibo.monto
            valores.interes += recibo.interes
            valores.multa += recibo.multa
            valores.total += recibo.total
        });
    }
    return valores
}


export default {
    namespaced: true,
    state: {
        cedula: "",
        nombre: "",
        errorDatos: '',
        convenios: {},
        cobros: {},
        parciales: {},
        vencidos: {},
        totalidad: {},
        historicos: {}
    },
    getters: {
        storeCedula: state => state.cedula,
        storeNombre: state => state.nombre,
        storeErrorDatos: state => state.errorDatos,
        storeConvenios: state => state.convenios,
        storeCobros: state => state.cobros,
        storeParciales: state => state.parciales,
        storeVencidos: state => state.vencidos,
        storeTotalidad: state => state.totalidad,
        storeHistoricos: state => state.historicos,
    },
    mutations: {
        CAMBIAR_CEDULA(state, payload) {
            state.cedula = payload;
        },
        CAMBIAR_NOMBRE(state, payload) {
            state.nombre = payload;
        },
        CAMBIAR_ERRORDATOS(state, payload) {
            state.errorDatos = payload;
        },
        CAMBIAR_CONVENIOS(state, payload) {
            state.convenios = payload;
        },
        CAMBIAR_COBROS(state, payload) {
            state.cobros = payload;
        },
        CAMBIAR_PARCIALES(state, payload) {
            state.parciales = payload;
        },
        CAMBIAR_VENCIDOS(state, payload) {
            state.vencidos = payload;
        },
        CAMBIAR_TOTALIDAD(state, payload) {
            state.totalidad = payload;
        },
        CAMBIAR_HISTORICOS(state, payload) {
            state.historicos = payload;
        },
    },
    actions: {
        async storeGetDocumentos({ commit }, cedula) {
            console.log(storeName + "storeGetDocumentos ::", cedula);
            try {
                const result = await axios.doConsulta(cedula);

                // console.log("Result --->", result);
                // const datos = JSON.parse(result.data)

                let datos = result.data
                // console.log('datos :>> ', datos);
                if (datos.cedula) {
                    commit("CAMBIAR_CEDULA", datos.cedula)
                    commit("CAMBIAR_NOMBRE", datos.nombre)
                    commit("CAMBIAR_COBROS", datos.cobros)

                    if (datos.historico) {
                        commit("CAMBIAR_HISTORICOS", datos.historico)
                    } else {
                        commit("CAMBIAR_HISTORICOS", [])
                    }
                    commit("CAMBIAR_HISTORICOS", datos.historico)
                    commit("CAMBIAR_ERRORDATOS", "O.K.")

                    commit("CAMBIAR_PARCIALES", calcularTotales(datos.cobros.PAR))
                    commit("CAMBIAR_VENCIDOS", calcularTotales(datos.cobros.VEN))
                    commit("CAMBIAR_TOTALIDAD", calcularTotales(datos.cobros.TOT))

                } else {
                    alert(datos)
                    commit("CAMBIAR_CEDULA", "")
                    commit("CAMBIAR_NOMBRE", "")
                    commit("CAMBIAR_COBROS", {})
                    commit("CAMBIAR_HISTORICOS", [])
                    commit("CAMBIAR_PARCIALES", calcularTotales(null))
                    commit("CAMBIAR_VENCIDOS", calcularTotales(null))
                    commit("CAMBIAR_TOTALIDAD", calcularTotales(null))
                    return false
                }
                // console.log(' storeGetDocumentos result :>> ', result);
                return true
            } catch (error) {
                console.log(storeName + "storeGetDocumentos error :>> ", { ...error })
                commit("CAMBIAR_CEDULA", "")
                commit("CAMBIAR_NOMBRE", "")
                commit("CAMBIAR_COBROS", {})
                commit("CAMBIAR_ERRORDATOS", "Ocurrio un error procesando la consulta :" + cedula)
                return false
            }
        },
        /**
         *
         * @param {*} param0
         * @param {*} params
         * @returns Error   si existe mensaje de error en servidor
         */
        async storeSendPagos({ commit }, params) {
            console.log(storeName + "storeSendPagos ::", params);
            try {

                const result = await axios.doSendPagos(params);

                const jdata = JSON.parse(result.data.jresult);

                console.log("jdata.processUrl", jdata.processUrl);

                window.location = jdata.processUrl;

                return true
            } catch (error) {
                console.log(storeName + "error :>> ", { ...error })
            }
            return false
        },
    }
};




