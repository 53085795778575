import axios from "@/_API/AXIOS";

/**
http://172.16.24.25:8080/services/servicios
http://172.16.24.25:8080/services/restricciones
http://172.16.24.25:8080/services/paquetes
http://172.16.24.25:8080/services/consulta/203620831/p
Esos son los servicios

 */
const CONSULTA_URL = 'https://www.muniquepos.go.cr/pss_ecommerce/pss_service.php';

export default {
    async doConsulta(cedula) {

        // https://www.muniquepos.go.cr/pss_ecommerce/pss_service.php?cedula=0107790187
        return await axios().get("https://www.muniquepos.go.cr/pss_ecommerce/pss_service.php?cedula=" + cedula + "&service=ecommerce-vpos");
    },

    async doSendPagos(params) {
        console.log('doSendPagos pagos :>> ', params);
        const headers = { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }

        let formData = new FormData();
        formData.append("cedula", params.contribuyente.cedula.trim());
        formData.append("cedulatarjeta", params.contribuyente.cedulatarjeta.trim());
        formData.append("service", "ecommerce-p2p");
        formData.append("convenio", params.convenio.trim());
        formData.append("p2p_tipo", params.contribuyente.tipo);
        formData.append("p2p_cedula", params.contribuyente.cedula);
        formData.append("p2p_nombre", params.contribuyente.nombre);
        formData.append("p2p_apellidos", params.contribuyente.apellido);
        formData.append("p2p_phone", params.contribuyente.celular);
        formData.append("p2p_email", params.contribuyente.email);

        return await axios().post("https://www.muniquepos.go.cr/pss_ecommerce/pss_service.php", formData,  { headers });
    },
    async doStartBankPage() {

    }


};
