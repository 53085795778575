<template>
    <v-container fill-height fluid>
        <v-dialog v-model="flsShowContribuyente" hide-overlay transition="dialog-bottom-transition">
            <ContribuyenteForm :contribuyente="contribuyente" @modalResult="closeContribuyente" />
        </v-dialog>

        <v-card max-width="1200px" height="100%" justify="center">
            <!-- <v-toolbar color='primary' > -->
            <v-toolbar dark color="primary">
                <v-app-bar-nav-icon></v-app-bar-nav-icon>
                <v-toolbar-title dense class="toolbar-title">{{ storeCedula + ": " + storeNombre }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn light @click="doExit">
                    <v-icon class>mdi-close-box-outline</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row justify="center" dense class="my-auto">
                    <table class="letragrande">
                        <tbody>
                            <tr>
                                <th>Detalle</th>
                                <th>Vencido</th>
                                <th>Parcial</th>
                                <th>Total</th>
                            </tr>
                            <tr>
                                <td>Monto</td>
                                <td>{{ currencyFormat(storeVencidos.monto) }}</td>
                                <td>{{ currencyFormat(storeParciales.monto) }}</td>
                                <td>{{ currencyFormat(storeTotalidad.monto) }}</td>
                            </tr>
                            <tr>
                                <td>Interes</td>
                                <td>{{ currencyFormat(storeVencidos.interes) }}</td>
                                <td>{{ currencyFormat(storeParciales.interes) }}</td>
                                <td>{{ currencyFormat(storeTotalidad.interes) }}</td>
                            </tr>
                            <tr>
                                <td>Multa</td>
                                <td>{{ currencyFormat(storeVencidos.multa) }}</td>
                                <td>{{ currencyFormat(storeParciales.multa) }}</td>
                                <td>{{ currencyFormat(storeTotalidad.multa) }}</td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn rounded color="orange" dark v-bind="attrs" v-on="on" @click="vistaTipoTabla = 1">
                                                <h2>{{ currencyFormat(storeVencidos.total) }}</h2>
                                            </v-btn>
                                        </template>
                                        <span>Total Cobro Vencido : {{ currencyFormat(storeVencidos.total) }}</span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn rounded color="info" dark v-bind="attrs" v-on="on" @click="vistaTipoTabla = 2">
                                                <h2>{{ currencyFormat(storeParciales.total) }}</h2>
                                            </v-btn>
                                        </template>
                                        <span>Total Cobro Parcial : {{ currencyFormat(storeParciales.total) }}</span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn rounded color="success" dark v-bind="attrs" v-on="on" @click="vistaTipoTabla = 3">
                                                <h2>{{ currencyFormat(storeTotalidad.total) }}</h2>
                                            </v-btn>
                                        </template>
                                        <span>Total Cobros : {{ currencyFormat(storeTotalidad.total) }}</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </v-row>

                <v-row justify="center" dense class="mx-0 my-0" v-if="storeHistoricos.length > 0">
                    <h1>Historico de Pagos</h1>
                </v-row>
                <v-row justify="center" dense class="mx-0 my-0" v-if="storeHistoricos.length > 0">
                    <v-col lg="12" md="12" sm="12" xs="12">
                        <v-data-table height="20vh" class="elevation-1 pss_table" :hide-default-footer="true" dense multi-sort fixed-header mobile-breakpoint="0" :headers="encabezadoHistoricos" :items="storeHistoricos"></v-data-table>
                    </v-col>
                </v-row>

                <v-row justify="center" dense class="mx-0 my-0 gray">
                    <h2 v-if="vistaTipoTabla == 1 && storeVencidos.total > 0">Cobros Vencidos</h2>
                    <h2 v-if="vistaTipoTabla == 2 && storeParciales.total > 0">Cobros Parciales</h2>
                    <h2 v-if="vistaTipoTabla == 3 && storeTotalidad.total > 0">Detalles de Cobro</h2>
                </v-row>
                <v-row justify="center" dense class="mx-0 my-0" v-if="vistaTipoTabla == 1 && storeVencidos.total > 0">
                    <v-col lg="12" md="12" sm="12" xs="12">
                        <v-data-table height="20vh" class="elevation-1 pss_table" :hide-default-footer="true" dense multi-sort fixed-header mobile-breakpoint="0" :headers="encabezados" :items="storeVencidos.documentos">
                            <template v-slot:item.monto="{ item }">
                                <span>{{ currencyFormat(item.monto) }}</span>
                            </template>
                            <template v-slot:item.interes="{ item }">
                                <span>{{ currencyFormat(item.interes) }}</span>
                            </template>
                            <template v-slot:item.multa="{ item }">
                                <span>{{ currencyFormat(item.multa) }}</span>
                            </template>
                            <template v-slot:item.total="{ item }">
                                <span>{{ currencyFormat(item.total) }}</span>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>

                <v-row justify="center" dense class="mx-0 my-0" v-if="vistaTipoTabla == 2 && storeParciales.total > 0">
                    <v-col lg="12" md="12" sm="12" xs="12">
                        <v-data-table height="20vh" class="elevation-1 pss_table" :hide-default-footer="true" dense multi-sort fixed-header mobile-breakpoint="0" :headers="encabezados" :items="storeParciales.documentos">
                            <template v-slot:item.monto="{ item }">
                                <span>{{ currencyFormat(item.monto) }}</span>
                            </template>
                            <template v-slot:item.interes="{ item }">
                                <span>{{ currencyFormat(item.interes) }}</span>
                            </template>
                            <template v-slot:item.multa="{ item }">
                                <span>{{ currencyFormat(item.multa) }}</span>
                            </template>
                            <template v-slot:item.total="{ item }">
                                <span>{{ currencyFormat(item.total) }}</span>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>

                <v-row justify="center" dense class="mx-0 my-0" v-if="vistaTipoTabla == 3 && storeTotalidad.total > 0">
                    <v-col lg="12" md="12" sm="12" xs="12">
                        <v-data-table height="20vh" class="elevation-1 pss_table" :hide-default-footer="true" dense multi-sort fixed-header mobile-breakpoint="0" :headers="encabezados" :items="storeTotalidad.documentos">
                            <template v-slot:item.monto="{ item }">
                                <span>{{ currencyFormat(item.monto) }}</span>
                            </template>
                            <template v-slot:item.interes="{ item }">
                                <span>{{ currencyFormat(item.interes) }}</span>
                            </template>
                            <template v-slot:item.multa="{ item }">
                                <span>{{ currencyFormat(item.multa) }}</span>
                            </template>
                            <template v-slot:item.total="{ item }">
                                <span>{{ currencyFormat(item.total) }}</span>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-row justify="center" dense class="mx-0 my-0">
                    <v-btn x-large rounded color="success" :disabled="storeVencidos.total <= 0" @click="doProcesarPago()" v-if="vistaTipoTabla == 1">Pagar Vencidos {{ currencyFormat(storeVencidos.total) }}</v-btn>
                    <v-btn x-large rounded color="success" :disabled="storeParciales.total <= 0" @click="doProcesarPago()" v-if="vistaTipoTabla == 2">Pagar Parciales {{ currencyFormat(storeParciales.total) }}</v-btn>
                    <v-btn x-large rounded color="success" :disabled="storeTotalidad.total <= 0" @click="doProcesarPago()" v-if="vistaTipoTabla == 3">Pagar {{ currencyFormat(storeTotalidad.total) }}</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import ContribuyenteForm from "./Contribuyente.vue"

    export default {
        name: "ProcesosSeleecionPagoForm",
        props: ["contribuyente", "modalResult"],
        components: { ContribuyenteForm },
        data: () => ({
            mainTitle: process.env.VUE_APP_TITLE,
            formTitle: "",
            vistaTipoTabla: 1,
            flsShowContribuyente: false,
            encabezados: [
                { text: "Rubro", align: "center", value: "rubro", },
                { text: "Periodo", value: "periodo", },
                { text: "Descripcion", value: "descripcion", },
                { text: "Monto", align: "right", value: "monto", },
                { text: "Interes", align: "right", value: "interes", },
                { text: "Multa", align: "right", value: "multa", },
                { text: "Total", align: "right", value: "total", },
            ],

            encabezadoHistoricos: [
                { text: "Fecha", value: "hora", },
                { text: "Estado", align: "center", value: "estado", },
                { text: "Referencia", value: "referencia" },
                { text: "Moneda", align: "right", value: "moneda", },
                { text: "Monto", align: "right", value: "monto", },
                { text: "Autorizacion", align: "right", value: "autorizacion", },

            ]
        }),
        watch: {
            // vistaTipoTabla: {
            //     handler: function (after, before) {
            //         console.log("this.vistaTipoTabla :>> before :", before, "----> after :", after);
            //     },
            // },
        },

        async created() {
            this.initialize();
        },
        async mounted() {
            this.initialize();
        },
        computed: {
            ...mapGetters("ECommerce", ["storeCedula", "storeNombre", "storeErrorDatos", "storeConvenios", "storeHistoricos", "storeCobros", "storeParciales", "storeVencidos", "storeTotalidad",]),
        },
        methods: {
            ...mapActions("ECommerce", ["storeSendPagos"]),
            async initialize() {
                this.formTitle = "Seleccion de Documentos Cancelar";
                this.flsShowContribuyente = false
                this.vistaTipoTabla = 3

                console.log("contribuyente.cedula :>> ", this.contribuyente.cedula);
            },
            currencyFormat(numero) {
                if (numero) {
                    return numero.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                } else {
                    return "0.00"
                }
            },
            async closeContribuyente(accion) {
                console.log("Retorno de accion :>> ", accion);
                this.flsShowContribuyente = false;

                if (accion) {
                    let pagoTarjeta = {
                        convenio: "",
                        contribuyente: this.contribuyente
                    }
                    switch (this.vistaTipoTabla) {
                        case 1:
                            pagoTarjeta.convenio = "VEN"
                            break;
                        case 2:
                            pagoTarjeta.convenio = "PAR"
                            break;
                        default:
                            pagoTarjeta.convenio = "TOT"
                    }
                    try {
                        this.storeSendPagos(pagoTarjeta)
                    } catch (error) {
                        alert("Invocando V-Pos")
                    }
                }
            },
            doProcesarPago() {
                this.flsShowContribuyente = true;
            },
            doExit() {
                console.log("close Procesos de Seleccion de Documentos");
                this.$emit("modalResult", "close");
            },
        },
    };
</script>

<style scoped>
    .letragrande {
    	font-size: 1rem;
    }

    td {
    	text-align: right;
    	margin-left: 0.8em;
    	width: 15em;
    }
    th {
    	text-align: right;
    	margin-left: 0.75em;
    	width: 15em;
    }

    tr {
    	border-bottom: 1px solid black;
    }

    tr:nth-child(even) {
    	background-color: #d6eeee;
    }

    .v-card {
    	display: flex !important;
    	flex-direction: column;
    	height: 70vh;
    }

    .v-card__text {
    	flex-grow: 1;
    	overflow: auto;
    }
</style>
