<template>
    <v-container fill-height fluid>

        <div v-if="flsOnCargarDocumento" style="margin: 3rem">
            <v-row justify="center">
                <v-progress-circular :size="100" :width="7" color="info" indeterminate></v-progress-circular>
            </v-row>
        </div>

        <v-dialog v-model="flsShowTerminos" hide-overlay transition="dialog-bottom-transition">
            <TerminosyCondiciones @modalResult="closeTerminos" />
        </v-dialog>

        <v-dialog v-model="flsShowFAQ" hide-overlay transition="dialog-bottom-transition">
            <PrecuentasFrecuentes @modalResult="closeFAQ" />
        </v-dialog>

        <v-dialog v-model="flsShowProcesoSeleccion" hide-overlay transition="dialog-top-transition">
            <ProcesosSeleccionPago :contribuyente="contribuyente" @modalResult="closeProcesosSeleccion" />
        </v-dialog>

        <v-card class="mx-auto" width="500px" max-width="1000px" justify="center" align="center">
            <!-- <v-toolbar color='primary' > -->

            <v-toolbar dark color="primary">
                <v-app-bar-nav-icon></v-app-bar-nav-icon>
                <v-toolbar-title class="toolbar-title">{{ formTitle }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn light @click="doExit">
                    <v-icon class>mdi-close-box-outline</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text>
                <v-row class="justify-center align-center" dense align="center" justify="center">
                    <v-col lg="10" md="10" sm="10" xs="10">
                        <v-text-field clearable v-model="cedula" :rules="reglaCedula" label="Digite su Cédula" prepend-inner-icon="mdi-account-box-outline" required></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="justify-center align-center" dense align="center" justify="center">
                    <v-col lg="8" md="8" sm="8" xs="8">
                        <v-btn color="primary" dark class="ma-1 text-center" @click="flsShowTerminos = true">Mosrar Terminos y Condiciones</v-btn>
                    </v-col>
                    <v-col lg="4" md="4" sm="4" xs="4">
                        <v-btn color="primary" dark class="ma-1 text-center" @click="flsShowFAQ = true">FAQ</v-btn>
                    </v-col>
                </v-row>

                <v-row class="justify-center align-center" dense align="center" justify="center">
                    <v-col lg="12" md="12" sm="12" xs="12">
                        <v-switch class="text-center mx-5" v-model="flsAceptaTerminos" inset label="Acepta Terminos y Condiciones"></v-switch>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-row class="justify-center align-center">
                <a href="https://www.placetopay.com/web" target="_blank" class="ui medium image">
                    <img src="https://static.placetopay.com/placetopay-logo.svg" alt="place-to-pay" class="image img-placetopay" />
                </a>
            </v-row>
            <v-row class="justify-center align-center">
                <div class="ui image">
                    <img src="/franquicias.png" alt="franquicias" class="image img-placetopay" />
                </div>
            </v-row>

            <v-card-actions class="my-10 justify-center">
                <v-btn large :color="flsAceptaTerminos ? 'green' : 'red'" dark :disabled="!flsAceptaTerminos" @click="doProcesoSeleccion">Consultar Pendiente</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import TerminosyCondiciones from "@/views/TerminosyCondiciones";
import PrecuentasFrecuentes from "@/views/FAQ";
import ProcesosSeleccionPago from "@/views/ProcesosSeleccionPago";

import { mapGetters, mapActions } from "vuex";

export default {
    name: "ECommerce",
    components: {
        TerminosyCondiciones,
        PrecuentasFrecuentes,
        ProcesosSeleccionPago,
    },
    data: () => ({
        mainTitle: process.env.VUE_APP_TITLE,
        formTitle: "",
        cedula: "",
        tipoConsulta: "t",
        flsAceptaTerminos: false,
        flsOnCargarDocumento: false,
        flsShowFAQ: false,
        flsShowTerminos: false,
        flsShowProcesoSeleccion: false,
        contribuyente: {
            apellidos: '',
            cedula: '',
            cedulatarjeta: '',
            convenio: '',
            email: '',
            message: 'PARA PROCEDER CON EL PAGO NECESITAMOS ANTES QUE NOS INDIQUE ESTA INFROMACIÓN',
            nombre: '',
            phone: '',
            tipo: 'CRCPF'
        },
        reglaCedula: [v => !!v || 'Se requiere Cedula', v => v.length >= 5 && v.length <= 20 || 'Cedula debe tener 5 a 20 caracteres']
    }),
    watch: {
        // searchDialog: {
        //     handler: function (after, before) {
        //         console.log("this.searchDialog :>> before :", before, "----> after :", after);
        //     }
        // },
    },

    async created() {
        this.initialize();
    },
    computed: {
        ...mapGetters("ECommerce", []),
    },
    methods: {
        ...mapActions("ECommerce", ["storeGetDocumentos"]),
        async initialize() {
            this.formTitle = "Pagos en linea";
        },
        doExit() {
            window.location = "https://www.muniquepos.go.cr/"
        },
        closeFAQ(accion) {
            console.log("accion :>> ", accion);
            this.flsShowFAQ = false;
        },
        closeTerminos(accion) {
            console.log("accion :>> ", accion);
            this.flsShowTerminos = false;
        },
        closeProcesosSeleccion(accion) {
            console.log("accion :>> ", accion);
            this.flsShowProcesoSeleccion = false;
        },
        async doProcesoSeleccion() {
            this.flsOnCargarDocumento = true;
            this.flsShowProcesoSeleccion = false;
            this.cedula = this.cedula.trim()

            // if (this.cedula.match(/^\d+$/)) {
            //     // Formato de Cedula a 10 digitos
            //     this.cedula = ("00000000000000" + this.cedula).slice(-10)
            //     if (this.storeGetDocumentos(this.cedula)) {

            //         this.contribuyente.cedula = this.cedula
            //         this.flsOnCargarDocumento = false;

            //         this.flsShowProcesoSeleccion = true;
            //     }
            // } else {
            //     alert("Cedula Incorrecto")
            // }

            if (this.cedula.length >= 5 && this.cedula.length <= 20) {
                if (this.storeGetDocumentos(this.cedula)) {
                    this.contribuyente.cedula = this.cedula
                    this.flsOnCargarDocumento = false;
                    this.flsShowProcesoSeleccion = true;
                }
            } else {
                alert("Cedula Incorrecto")
            }
            this.flsOnCargarDocumento = false;
        },
    },
};
</script>

<style>

</style>
