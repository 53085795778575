<template>
    <v-card max-width="1200px" height="100%" justify="center">
        <v-toolbar dark color="primary">
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title class="toolbar-title">{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn light @click="doExit(false)">
                <v-icon class>mdi-close-box-outline</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card-text>
            <v-row dense>
                <v-col cols="4">
                    <v-select v-model="contribuyente.tipo" :items="tiposIdentificaciones" item-text="text" item-value="value" label="Tipo Documento" single-line></v-select>
                </v-col>
                <v-col cols="8">
                    <v-text-field clearable v-model="contribuyente.cedulatarjeta" :rules="reglaSoloNumeros" label="Digite su Cédula"></v-text-field>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="6">
                    <v-text-field clearable v-model="contribuyente.nombre" :rules="reglaSoloLetras" label="Nombre"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field clearable v-model="contribuyente.apellido" :rules="reglaSoloLetras" label="Apellido"></v-text-field>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="4">
                    <v-text-field clearable v-model="contribuyente.celular" :rules="reglaSoloNumeros" label="Celular"></v-text-field>
                </v-col>
                <v-col cols="8">
                    <v-text-field clearable v-model="contribuyente.email" :rules="reglaCorreoElectronico" label="Correo Electronico"></v-text-field>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-btn large rounded @click="doExit(false)" color="orange">
                <v-icon>mdi-cancel</v-icon>Regresar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn large rounded @click="doExit(true)" color="success">Proceder con el Pago</v-btn>

            <!-- <v-btn large outlined @click="doExit(false)">Regresar</v-btn>
                <v-spacer></v-spacer>
            <v-btn large round  @click="doExit(true)">Proceder con el Pago</v-btn>-->
        </v-card-actions>
    </v-card>
</template>

contribuyente

<script>
    import { mapGetters, mapActions } from "vuex";

    export default {
        name: "InformacionesContribuyentesForm",
        props: ["contribuyente", "modalResult"],
        components: {},
        data: () => ({
            reglaSoloNumeros: [v => !!v || 'Se requiere Cedula',   v => /^\d+$/.test(v) || 'Solo Numeros'],
            reglaSoloLetras: [v => !!v || 'Se requiere Nombre(s) / Apellidos',  v => /^[a-zA-ZÑñáéíóúÁÉÍÓÚ\s]*$/.test(v) || 'Solo Letras'],
            reglaCorreoElectronico: [v => !!v || 'Se requiere Correo Electronico',  v => /^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i.test(v) || 'Formato de Correo no valido'],

            mainTitle: process.env.VUE_APP_TITLE,
            formTitle: "",
            flsProcederPago: false,
            tiposIdentificaciones: [
                {
                    value: "CRCPF",
                    text: "Cédula Física",
                },
                {
                    value: "CPJ",
                    text: "Cédula Jurídica",
                },
                {
                    value: "DIMEX",
                    text: "DIMEX",
                },
                {
                    value: "DIDI",
                    text: "DIDI - Diplomáticos",
                },
            ],
        }),
        watch: {
            // // contribuyente: {
            // //     handler: function (after, before) {

            // //     }            contribuyente: {
            //     handler: function (after, before) {

            //     }
            // },

            // // },
        },

        async created() {
            this.initialize();
        },
        computed: {
            ...mapGetters("ECommerce", ["storeCedula", "storeNombre", "storeErrorDatos", "storeConvenios", "storeCobros", "storeParciales", "storeVencidos", "storeTotalidad",]),
        },
        methods: {
            async initialize() {
                this.formTitle = "Datos de tarjetahabiente";
                this.flsProcederPago = false
                console.log('this.contribuyente :>> ', this.contribuyente);
            },
            doExit(accion) {
                console.log("Close Contribuyentes", accion);
                let msjErrores = []
                if (accion) {

                    this.flsProcederPago = false
                    console.log('this.contribuyente.tipo :>> ', this.contribuyente.tipo);
                    if (!this.tiposIdentificaciones.filter(x => x.value == this.contribuyente.tipo)) {
                        msjErrores.push("Tipo de Documento no corresponde: " + this.contribuyente.tipo + "\n\n")
                    }
                    if (!this.contribuyente.cedulatarjeta.match(/^\d+$/)) {
                        msjErrores.push("Error en Numero de Identificacion\n\n")
                    }
                    if (!this.contribuyente.apellido.match(/^[a-zA-ZÑñáéíóúÁÉÍÓÚ\s]*$/) || this.contribuyente.apellido.length < 2) {
                        msjErrores.push("Falta llenar Campo de Apellido o Apellido no es Correcto\n\n")
                    }
                    if (!this.contribuyente.nombre.match(/^[a-zA-ZÑñáéíóúÁÉÍÓÚ\s]*$/) || this.contribuyente.nombre.length < 2) {
                        msjErrores.push("Falta llenar Campo de Nombre o Nombre no es correcto\n\n")
                    }
                    if (!this.contribuyente.celular.match(/^\d+$/)) {
                        msjErrores.push("Falta llenar Campo de Celular o Celular no es correcto\n\n")
                    }
                    if (!this.contribuyente.email.match(/^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i)) {
                        msjErrores.push("Falta llenar Campo de E-Mail o E-Mail no es correcto\n\n")
                    }
                    console.log(' msjErrores :>> ', msjErrores);
                    this.flsProcederPago = msjErrores.length == 0
                    if (this.flsProcederPago) {
                        this.$emit("modalResult", true);
                    } else {
                        alert(msjErrores)
                    }
                } else {
                    this.$emit("modalResult", false);
                }
            },
        },
    };
</script>

<style scoped>
    html {
    	overflow: hidden !important;
    }

    .v-card {
    	display: flex !important;
    	flex-direction: column;
    }

    .v-card__text {
    	flex-grow: 1;
    	overflow: auto;
    }
</style>
