<template>
    <v-app>
        <v-app-bar app light color="transparent">
            <v-spacer />
            <v-toolbar-title style="color: white;" class="toolbar-title" dark>
                <h2>Municipalidad de Quepos - Pagos en Linea</h2>
            </v-toolbar-title>
            <v-spacer />
        </v-app-bar>
        <img class="imagenLogo" src="/logo.png" />
        <ECommerce />
    </v-app>
</template>

<script>
import ECommerce from "@/views/ECommerce"
export default {
    name: 'App',
    components: { ECommerce },

    data: () => ({

    }),
    methods: {
    },
};
</script>
<style>
#app {
    background: transparent;
}

body {
    background-image: url("/fondo.jpg");
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center 0;
    background-repeat: no-repeat;
}

.imagenLogo {
    position: absolute;
    top: 2rem;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -3;
}
</style>

