<template>
<v-card  width='700px' max-width='1000px' height="600px" max-height="1000px" justify='center'>
    <!-- <v-toolbar color='primary' > -->
    <v-toolbar dark color='primary'>
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
        <v-toolbar-title  class='toolbar-title'>{{ formTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn light  @click='doExit'>
            <v-icon class>mdi-close-box-outline</v-icon>
        </v-btn>
    </v-toolbar>

    <v-card-text>
        <v-container fluid>
            <h4>Terminos y Condiciones</h4>
                <h3>PAGO ELECTRONICO DE IMPUESTOS MUNICIPALES TASAS O SERVICIOS MEDIANTE EL USO DE TARJETA VISA O MASTERCARD.</h3>
            <p>
                La Municipalidad de Quepos a través de una alianza financiera con el Banco de Costa Rica, pone a disposición de todos los Contribuyentes del Cantón el pago de impuestos municipales, servicios y tasas mediante el uso del sitio web https://www.muniquepos.go.cr que nuestro municipio posee. Utilizando como medio de pago su tarjeta de debido o crédito (colones o dólares) VISA o MASTERCARD de cualquier emisor financiero.
            </p>
            <p>
                Por su seguridad y la nuestra todas las transacciones tienen soporte de seguridad financiero y electrónico por medio del protocolo de navegación segura https, por lo cual la Municipalidad de Quepos, garantiza que cumple con las normas técnicas nacionales e internacionales debidamente habladas por nuestro socio estratégico el Banco Nacional de Costa Rica.
            </p>
                <h3>POLITICAS Y CONDICIONES:</h3>
            <p>
                <b>Registro del Cliente:</b>
            </p>
            <p>
                Por su seguridad la Municipalidad de Quepos no solicitará información de sus tarjetas de débito o crédito.
            </p>
            <p>
                Autorización de pagos con tarjeta de crédito y o débito:
            </p>
            <p>
                Una vez que usted decida pagar en firme y sea necesaria la aprobación del pago, nuestro sitio web utilizará el generador y verificador de gestión de cobro del Banco Nacional de Costa Rica de una manera SEGURA Y EFICAZ.
            </p>
            <p>
                La Municipalidad de Quepos no venderá, no proporcionará, no intercambiará, ni divulgará información de las calidades de nuestros contribuyentes.
            </p>
                <h3>En Relación con los Pagos:</h3>
            <p>
                Estimado Contribuyente a continuación encontrará las diferentes modalidades de pago que hemos puesto a su disposición; con la finalidad de facilitar el pago de sus impuestos, tasas o servicios y así juntos fomentemos una sana y oportuna cultura de pago electrónico, realizando todas las transacciones desde la comodidad de su casa, trabajo, empresa o cualquier otro lugar usted pueda cumplir y estar al día con sus responsabilidades tributarias.
            </p>
            <p>
                PAGO VENCIDO: Bajo esta modalidad usted realizará la cancelación de todos los tributos de periodos anteriores, cuya fecha de vencimiento de pago ya fue sobrepasada.
            </p>
            <p>
                PAGO PARCIAL: Cancelación de todos los tributos vencidos y los tributos que se encuentran al cobro a la fecha de realización del pago.
            </p>
            <p>
                PAGO TOTAL: Cancelación de los tributos vencidos, puestos al cobro y todos los demás hasta el 31 de Diciembre del año en curso.
            </p>
            <p>
                Por la naturaleza del pago que se realiza por medios electrónicos, la Municipalidad de Quepos no extenderá ningún documento tipo recibo, ya que para estos efectos una vez finalizada la transacción electrónica el módulo de pago del Banco Nacional le extenderá el respectivo comprobante, así como también el movimiento quedará debidamente registrado a nivel de los Sistemas de Información de este ente Bancario.
            </p>
            <p>
                Los tarjetahabientes que utilicen las modalidades de pago antes descritas son responsables por las instrucciones de pago que así realicen. La Municipalidad de Quepos de Heredia no reembolsará pagos realizados mediante estas modalidades de pago.
            </p>

        </v-container>
    </v-card-text>
    <v-divider class='mx-4'></v-divider>
    <v-card-actions>
    </v-card-actions>
</v-card>
</template>

<script>
export default {
    name: "TerminosyCondiciones",
    props: ["modalResult"],
    components: {},
    data: () => ({
        mainTitle: process.env.VUE_APP_TITLE,
        formTitle: "",

    }),
    watch: {
        // searchDialog: {
        //     handler: function (after, before) {
        //         console.log("this.searchDialog :>> before :", before, "----> after :", after);
        //     }
        // },
    },

    async created() {
        this.initialize();
    },
    computed: {},
    methods: {
        async initialize() {
            this.formTitle = "Terminos y Condicicones"

        },
        doExit() {
            console.log('close terminos');
            this.$emit("modalResult", 'close');
        }
    }
};
</script>

<style scoped>

html {
  overflow: hidden !important;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>
